<script setup>
import {computed, onMounted, ref, watch} from "vue";
import store from "@/store";
import BrandedBox from "@/components/BrandedBox.vue";
import {useToast} from "vue-toastification";
import router from "@/router";
import {DateTime} from "luxon";
import {useRoute} from "vue-router";
import AccountLogin from "@/components/AccountLogin.vue";
import AccountRegister from "@/components/AccountRegister.vue";

onMounted(async () => {
  const {email, token} = useRoute().query;

  if (email && token) {
    await store.dispatch('login', {email, token})
    creditCard.value = store.getters.user.billingMethod === 'default';
  }
});

const user = computed(() => store.getters.user);
const showLoginLink = ref(false);

const creditCard = ref(!user.value || user.value.billingMethod === 'default');
const updateMethod = () => {
  store.commit('billingMethod', creditCard.value ? 'default' : 'invoice');
}
watch(creditCard, updateMethod);
updateMethod();

const bookingDaysAhead = store.getters.site.bookingDaysAhead;
const min = computed(() => {
  if (creditCard.value) {
    return DateTime.now().toISODate();
  }

  let start = DateTime.now().plus({days: 3});

  if (start.weekday === 6) {
    start = start.plus({days: 2});
  } else if (start.weekday === 7) {
    start = start.plus({days: 1});
  }

  return start.toISODate();
});
const max = computed(() => bookingDaysAhead && bookingDaysAhead > 0
    ? DateTime.now().plus({days: bookingDaysAhead}).toISODate()
    : null);
const start = ref(min.value);
const updateStart = () => store.commit('start', start.value);
watch(start, updateStart);
updateStart();

const logout = async () => {
  await router.push('/');
  await store.dispatch('logout');
  useToast().error('Benutzer abgemeldet.')
}
</script>

<template>
  <div v-if="!user">
    <account-login v-if="showLoginLink" @login-link-hide="showLoginLink = false"/>
    <account-register v-else @login-link-show="showLoginLink = true"/>
  </div>
  <branded-box v-else title="Ihre Daten">
    <table style="width:100%">
      <tbody>
      <tr>
        <td class="align-top"><i class="bi bi-person"></i></td>
        <td style="width:100%">
          <b>Name</b><br>
          {{ user?.firstName }} {{ user?.lastName }}<br>
        </td>
      </tr>
      <tr>
        <td class="align-top"><i class="bi bi-house"></i></td>
        <td>
          <b>Rechnungsadresse</b><br>
          <span style="white-space:pre-wrap">{{ user?.address }}</span>
        </td>
        <td></td>
      </tr>
      <tr>
        <td class="align-top"><i class="bi bi-phone"></i></td>
        <td>
          <b>Telefon</b><br>
          {{ user?.phone }}
        </td>
        <td></td>
      </tr>
      <tr>
        <td class="align-top"><i class="bi bi-mailbox"></i></td>
        <td>
          <b>E-Mail</b><br>
          {{ user?.email }}
        </td>
        <td></td>
      </tr>
      </tbody>
    </table>
    <p class="mx-3"><a href="#" @click.prevent="logout">Abmelden</a></p>
  </branded-box>

  <branded-box title="Ihre Miete">
    <div class="row gy-3">
      <div class="col-6">
        <label for="address" class="form-label">Zahlungsmittel</label>
        <select class="form-select" v-model="creditCard" :disabled="user">
          <option :value="true">Kreditkarte</option>
          <option :value="false">Rechnung (+5 CHF/Monat)</option>
        </select>
      </div>

      <div class="col-6">
        <label for="date" class="form-label">Startdatum</label>
        <input
            required
            id="date"
            type="date"
            class="form-control"
            v-model="start"
            :min="min"
            :max="max"
        />
      </div>

      <div class="col-12" v-if="!creditCard">
        <div class="text-white bg-primary text-center p-3">
          Bei Zahlung per Rechnung, wird Ihnen der Zutritt frühestens in 3 Werktagen gewährleistet -
          vorausgesetzt, dass Ihre Überweisung bis dahin eingetroffen ist.
        </div>
      </div>
    </div>
  </branded-box>
</template>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 1em;
}
</style>